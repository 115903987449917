<script setup>
import { ref, watch } from 'vue'
import FormComponent from './FormComponent.vue'
import WhatsAppIcon from './icons/WhatsAppIcon.vue'
import ContactIcon from './icons/ContactIcon.vue'
defineProps({
  projectId: {
    type: String,
    required: true
  },
  token: {
    type: String,
    required: true
  },
  phone: {
    type: String,
    required: false
  },
  phoneText: {
    type: String,
    required: false
  },
  showHeaderIcon: {
    type: Boolean,
    required: false,
    default: true
  },
  headerTitle: {
    type: String,
    required: false,
    default: null
  },
  title: {
    type: String,
    required: false,
    default: null
  },
  subtitle: {
    type: String,
    required: false,
    default: null
  },
  modalHeaderAndButtonBackgroundColor: {
    type: String,
    required: false,
    default: null
  },
  buttonTextColor: {
    type: String,
    required: false
  },
  isButton: {
    type: Boolean,
    required: false,
    default: true
  },
  top: {
    type: String,
    required: false,
    default: null
  },
  bottom: {
    type: String,
    required: false
  },
  left: {
    type: String,
    required: false
  },
  right: {
    type: String,
    required: false,
    default: null
  },
  successTitle: {
    type: String,
    required: false,
    default: null
  },
  successSubtitle: {
    type: String,
    required: false
  },
  customer: {
    type: String,
    required: false
  },
  mobysuiteVersion: {
    type: Number,
    required: false
  },
  utm_source: {
    type: String,
    required: false,
    default: null
  },
  utm_medium: {
    type: String,
    required: false,
    default: null
  },
  utm_campaign: {
    type: String,
    required: false,
    default: null
  },
  utm_term: {
    type: String,
    required: false,
    default: null
  },
  utm_content: {
    type: String,
    required: false,
    default: null
  },
  information_medium: {
    type: String,
    required: false,
    default: null
  },
  source: {
    type: String,
    required: false,
    default: null
  }
})
const showModal = ref(false)
const mobysuiteWebContactClosingModal = ref(false)

const modal = ref(null)

watch(showModal, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      modal.value.classList.contains('mobysuite-web-contact-showing')
    }, 1)
  }
})

watch(mobysuiteWebContactClosingModal, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      showModal.value = false
      mobysuiteWebContactClosingModal.value = false
    }, 300)
  }
})
</script>

<template>
  <div v-if="isButton">
    <div
      class="mobysuite-web-contact-fixed"
      :style="{ bottom: top ? null : bottom, left: right ? null : left, top: top, right: right }"
    >
      <!-- Botón de WhatsApp -->
      <!-- Contenedor del botón de WhatsApp -->
      <button
        @click="showModal = true"
        class="mobysuite-web-contact-whatsapp-button mobysuite-web-contact-relative mobysuite-web-contact-flex mobysuite-web-contact-rounded-full mobysuite-web-contact-p-5 mobysuite-web-contact-items-center mobysuite-web-contact-justify-center mobysuite-web-contact-overflow-hidden mobysuite-web-contact-font-medium mobysuite-web-contact-text-white mobysuite-web-contact-shadow-2xl mobysuite-web-contact-transition-all mobysuite-web-contact-duration-300 before:mobysuite-web-contact-absolute before:mobysuite-web-contact-rounded-full before:mobysuite-web-contact-inset-0 before:mobysuite-web-contact-border-0 before:mobysuite-web-contact-border-white before:mobysuite-web-contact-duration-100 before:mobysuite-web-contact-ease-linear hover:mobysuite-web-contact-bg-white hover:mobysuite-web-contact-text-green-600 hover:before:mobysuite-web-contact-border-[25px]"
        style="z-index: 1000"
        :class="
          phone
            ? 'mobysuite-web-contact-bg-green-600 hover:mobysuite-web-contact-shadow-green-600'
            : 'mobysuite-web-contact-bg-black hover:mobysuite-web-contact-shadow-gray-500'
        "
      >
        <span class="mobysuite-web-contact-relative mobysuite-web-contact-z-10"
          ><WhatsAppIcon v-if="phone" class="mobysuite-web-contact-whatsapp-icon" /><ContactIcon
            v-else
            class="mobysuite-web-contact-contact-icon"
        /></span>
      </button>
    </div>
    <div
      ref="modal"
      :class="{
        'mobysuite-web-contact-showing': showModal,
        'mobysuite-web-contact-closing': mobysuiteWebContactClosingModal
      }"
      class="mobysuite-web-contact-modal-mask"
      v-show="showModal"
      @click="mobysuiteWebContactClosingModal = true"
    >
      <div class="mobysuite-web-contact-modal-wrapper">
        <div class="mobysuite-web-contact-modal-container" @click.stop="">
          <div
            :class="{
              'mobysuite-web-contact-modal-header mobysuite-web-contact-flex color-mobysuite-blue text-md mobysuite-web-contact-font-bold mobysuite-web-contact-justify-between': true
            }"
            :style="{
              backgroundColor: phone
                ? modalHeaderAndButtonBackgroundColor
                  ? modalHeaderAndButtonBackgroundColor
                  : '#25d366'
                : modalHeaderAndButtonBackgroundColor
                ? modalHeaderAndButtonBackgroundColor
                : 'black'
            }"
          >
            <div class="mobysuite-web-contact-flex">
              <WhatsAppIcon v-if="showHeaderIcon && phone" />
              <ContactIcon v-else-if="showHeaderIcon && !phone" />
              <div class="mobysuite-web-contact-flex mobysuite-web-contact-items-center">
                <p :class="{ 'mobysuite-web-contact-px-2': showHeaderIcon }">{{ headerTitle }}</p>
              </div>
            </div>
            <div
              @click="mobysuiteWebContactClosingModal = true"
              class="mobysuite-web-contact-flex mobysuite-web-contact-items-center mobysuite-web-contact-cursor-pointer"
            >
              X
            </div>
          </div>
          <div class="mobysuite-web-contact-modal-body">
            <FormComponent
              :token="token"
              :project-id="projectId"
              :phone="phone"
              :phone-text="phoneText"
              :header-title="headerTitle"
              :mobysuite-web-contact-modal-header-and-button-background-color="
                modalHeaderAndButtonBackgroundColor
              "
              :button-text-color="buttonTextColor"
              :title="title"
              :subtitle="subtitle"
              :success-title="successTitle"
              :success-subtitle="
                phone
                  ? successSubtitle
                    ? successSubtitle
                    : 'Se abrirá una pestaña con nuestro chat de WhatsApp en breve.'
                  : successSubtitle
                  ? successSubtitle
                  : 'Nos pondremos en contacto contigo a la brevedad.'
              "
              :mobysuite-web-contact-modal-header-background-color="
                modalHeaderAndButtonBackgroundColor
              "
              :customer="customer"
              :version="version"
              :utm_source="utm_source"
              :utm_medium="utm_medium"
              :utm_campaign="utm_campaign"
              :utm_term="utm_term"
              :utm_content="utm_content"
              :information_medium="information_medium"
              :source="source"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="mobysuite-web-contact-p-5">
    <FormComponent
      :token="token"
      :project-id="projectId"
      :mobysuite-web-contact-modal-header-and-button-background-color="
        modalHeaderAndButtonBackgroundColor
      "
      :button-text-color="buttonTextColor"
      :title="title"
      :subtitle="subtitle"
      :success-title="successTitle"
      :success-subtitle="
        phone
          ? successSubtitle
            ? successSubtitle
            : 'Se abrirá una pestaña con nuestro chat de WhatsApp en breve.'
          : successSubtitle
          ? successSubtitle
          : 'Nos pondremos en contacto contigo a la brevedad.'
      "
      :mobysuite-web-contact-modal-header-background-color="modalHeaderAndButtonBackgroundColor"
      :customer="customer"
      :version="version"
      :utm_source="utm_source"
      :utm_medium="utm_medium"
      :utm_campaign="utm_campaign"
      :utm_term="utm_term"
      :utm_content="utm_content"
      :information_medium="information_medium"
      :source="source"
    />
  </div>
</template>
